<template>
  <div class="area_search">
    <Item
        :data="{
              id: 'form_biz_no',
              type: 'text',
              placeholder: $store.state.ui.view === 'mobile'? '사업자번호를 입력하세요.' : '사업자번호를 입력하세요.(숫자만)',
              btn: true,
              btnNm: '조회',
              dot: true,
            }"
        :value.sync="bizNo"
        @enter="fnSearchCompany"
        @onClick="fnSearchCompany"
    />
  </div>
</template>
<script>
import companyMix from "../../mixins/companyMix";
import Item from '~/components/common/ItemForm.vue';
export default {
  components:{Item},
  mixins:[companyMix],
  data(){
    return{
      bizNo:null,
      isApp: false,
      isMobile: false
    }
  },
  mounted() {
    this.isApp = this.$appCheck().isApp;
    this.isMobile = this.$store.state.ui.device === 'mobile';
    this.$store.commit('cmnCompanyStore/CLEAR')
  },
  methods:{
    async fnSearchCompany(){
      const bizNoValid = this.mixFnValidateBizNo(this.bizNo);
      if(!bizNoValid.success){
        this.$fnAlert(bizNoValid.message)
      }
      else {
        // 진행중인 상담이 있는지 확인
        const availConsulting = await this._fnCheckAvailConsulting();
        if( availConsulting ) {
          // 사업자번호로 회사정보 조회
          await this._fnFetchCompanyInfo();
        }
      }
    },

    async _fnCheckAvailConsulting(){
      const result = await this.mixFnCheckAvailConsulting(this.bizNo);
      // debugger
      if ( !result.error ){
        if ( result.isAvailConsulting ) {
          if ( result.isMyCompany ) {
            let data  = {
              state:true,
              type: 'main',
              msg: "이미 조회가 완료된 기업입니다.<br/>기존 결과를 보시려면 <strong>'마이페이지'</strong><br/>추가 진단을 받으시려면 <strong>'추가진단'</strong><br/>버튼을 클릭해주세요.",
              btns: [
                {
                  name: '마이페이지',
                  func: async ()=>{
                    this.$fnClosePopup();
                    await this.$router.push('/mypage/inquiry');
                  }
                },
                {
                  name: '추가 진단',
                  func: async ()=>{
                    this.$fnClosePopup();
                    await this.$store.commit('cmnCompanyStore/UPDATE_COMPANY_INFO', {bizNo:this.bizNo})
                    await this.$store.dispatch('cmnCompanyStore/fetchCompanyInfo', this.bizNo);
                    await this.$router.push('/services/serviceSelect')
                  }
                }
              ]
            };
            await this.$store.dispatch('ui/setPopupAlertData', data);
          }else{
            return true;
          }
        }else{
          this.$fnAlert('이미 조회가 완료된 기업입니다.<br/> 유선으로 문의해주시거나 다른 기업을 조회해주세요.');
          return false;
        }
      } else {
        this.$fnAlert(result.errorMessage);
        return false;
      }
    },

    async _fnFetchCompanyInfo(){
      // await this.$store.commit('cmnCompanyStore/UPDATE_COMPANY_INFO', {bizNo:this.bizNo})
      await this.$store.dispatch('cmnCompanyStore/fetchCompanyInfo', this.bizNo);
      // 조회에 성공하면 세션스토어에 넣는다.
      if(this.isApp||this.isMobile){
        await this.$router.push("/app/confirm")
      } else{
        await this.$router.push("/services/confirm")
      }
    },
  }
}
</script>
