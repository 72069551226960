<template>
  <div class="wrap">
    <section class="visual">
      <div class="bx_logo animate__animated"  :class="{
        'animate__fadeIn animate__slower':aniInfo.visual.h1 == 'true',
        'hide':aniInfo.visual.h1 == 'hide'}">
        <h1 class="logo">다된다 로고</h1>
      </div>
      <swiper class="sw_visual" ref="swiper" :options="swiperOption" @slideChange="fnSlideChange">
        <swiper-slide class="sw2">
          <article class="cont">
            <div class="left">
              <h1 class="animate__animated" :class="{
                'animate__fadeInDown':aniInfo.visual.h1 == 'true',
                'hide':aniInfo.visual.h1 == 'hide'
              }">다된다!</h1>
              <h2 class="animate__animated" :class="{
                'animate__fadeInDown animate__delay_500ms':aniInfo.visual.h2 == 'true',
                'hide':aniInfo.visual.h2 == 'hide'
              }">
                최대 5년치 <b>세금환급</b> <br/>
                최대 2년치 <b>고용지원금,</b> <br/>
                <strong class="ani_typing">다된다<em>cursor</em></strong>에서 다된다!
              </h2>
              <p class="animate__animated desc" :class="{
                'animate__fadeInDown animate__delay_1500ms':aniInfo.visual.p == 'true',
                'hide':aniInfo.visual.p == 'hide'
              }">최대 5년 중 최대 환급가능 세금 확인 <br/>최대 2년간 고용지원금 확인 및 신청까지 <br/>모두 다된다!</p>
            </div>
            <div class="right">
              <img class="img animate__animated" :class="{
                'animate__fadeInDown animate__delay_500ms': aniInfo.visual.p == 'true',
                'animate__fadeInUp': (aniInfo.visual.p == 'true' && $store.state.ui.view=='mobile'),
                'animate__fadeInDown': (aniInfo.visual.p == 'true' && $store.state.ui.view=='tablet'),
                'hide':aniInfo.visual.p == 'hide'
              }" :src="$store.state.ui.url+'/img/main/visual2.png'" alt="메인 비주얼 이미지2">
            </div>
          </article>
        </swiper-slide>
        <swiper-slide class="sw3">
          <article class="cont">
            <div class="left">
              <h1 class="animate__animated" :class="{
                'animate__fadeInDown':aniInfo.visual.h1 == 'true',
                'hide':aniInfo.visual.h1 == 'hide'
              }">다된다!</h1>
              <h2 class="animate__animated" :class="{
                'animate__fadeInDown animate__delay_500ms':aniInfo.visual.h2 == 'true',
                'hide':aniInfo.visual.h2 == 'hide'
              }">
              연평균 15% <b>4대보험료 절감</b> <br/><b>노무제도 정비</b> <br/>
                <strong class="ani_typing">다된다<em>cursor</em></strong>에서 다된다!
              </h2>
              <p class="animate__animated desc" :class="{
                'animate__fadeInDown animate__delay_1500ms':aniInfo.visual.p == 'true',
                'hide':aniInfo.visual.p == 'hide'
              }">연 평균 15% 기업 4대보험료 절감 <br/>근로자 1인당 연평균 48만원 절감까지 <br/>모두 다된다!</p>
            </div>
            <div class="right">
              <img class="img animate__animated" :class="{
                'animate__fadeInDown animate__delay_500ms': aniInfo.visual.p == 'true',
                'animate__fadeInUp': (aniInfo.visual.p == 'true' && $store.state.ui.view=='mobile'),
                'animate__fadeInDown': (aniInfo.visual.p == 'true' && $store.state.ui.view=='tablet'),
                'hide':aniInfo.visual.p == 'hide'
              }" :src="$store.state.ui.url+'/img/main/visual3.png'" alt="메인 비주얼 이미지3">
            </div>
          </article>
        </swiper-slide>
        <swiper-slide class="sw1">
          <article class="cont">
            <div class="left">
              <h1 class="animate__animated" :class="{
                'animate__fadeInDown':aniInfo.visual.h1 == 'true',
                'hide':aniInfo.visual.h1 == 'hide'
              }">다된다!</h1>
              <h2 class="animate__animated" :class="{
                'animate__fadeInDown animate__delay_500ms':aniInfo.visual.h2 == 'true',
                'hide':aniInfo.visual.h2 == 'hide'
              }">
                사업자번호만 입력하면 <br/>
                <p v-show="this.$store.state.ui.view === 'tablet'">
                  <b>높은한도&낮은금리로 맞춤형 금융매칭,</b>
                </p>
                <p v-show="this.$store.state.ui.view != 'tablet'"><b>높은한도 & 낮은금리</b>로 <br/>
                내회사 맞춤형 기업대출, <br/></p>
                <strong class="ani_typing">다된다<em>cursor</em></strong>에서 <b>다된다!</b>
              </h2>
              <p class="animate__animated desc" :class="{
                'animate__fadeInDown animate__delay_1500ms':aniInfo.visual.p == 'true',
                'hide':aniInfo.visual.p == 'hide'
              }">최적의 금융상품 및 정책자금 <br/>매칭부터 신청까지 모두 다된다!</p>
            </div>
            <div class="right">
              <img class="img animate__animated" :class="{
                'animate__fadeInDown animate__delay_500ms': aniInfo.visual.p == 'true',
                'animate__fadeInUp': (aniInfo.visual.p == 'true' && $store.state.ui.view=='mobile'),
                'animate__fadeInDown': (aniInfo.visual.p == 'true' && $store.state.ui.view=='tablet'),
                'hide':aniInfo.visual.p == 'hide'
              }" :src="$store.state.ui.url+'/img/main/visual1_2.svg'" alt="메인 비주얼 이미지1">
            </div>
          </article>
        </swiper-slide>
        <div slot="pagination" class="sw_pagination ac"></div>
      </swiper>
      <BizSearch />
    </section>
     <section class="area1">
      <div class="inner">
        <h2><b class="txt_point">사업자번호 조회</b>로 한번에!</h2>
        <div class="col3">
          <article class="bx_sc_info s2">
            <h2>세금환급</h2>
            <h3>최대 5년 중 <br/>최대 환급가능 금액<br class="p_show"/> 확인!</h3>
            <p>사업자라면 누구나<br class="p_show"/> 예상환급액 <br/>실시간 조회부터<br class="p_show"/> 환급신청까지!!</p>
          </article>
          <article class="bx_sc_info s4">
            <h2>4대보험료 <br class="p_show"/>절감</h2>
            <h3>연 평균 15% 절감!!</h3>
            <p>근로자 1인당<br class="p_show"/> 연평균 48만원 절감<br/><span>사업기간동안<br class="p_show"/> 매년절감 !!</span></p>
          </article>
          <article style="display: unset;" class="bx_sc_info s3">
            <h2>고용지원금</h2>
            <h3>최대 2년간 지원!</h3>
            <p>청년을 채용해도, <br/>50세 이상의 중년을 채용해도,<br/><span>근로자 1인당 최대 1200만원 지원 가능</span></p>
          </article>
          <article class="bx_sc_info s1">
            <h2>기업대출</h2>
            <h3>사업자번호로<br class="p_show"/> 간편하게 조회! <br/>기업대표라면 누구나!</h3>
            <p>TCB기술금융,<br class="p_show"/> IP담보대출, 정책자금<br/>1,2금융권 매칭 가능!</p>
          </article>
          <!-- <article class="bx_sc_info s5">
            <h2>법무등기</h2>
            <h3>법인설립 부터<br/>법인 주요 등기관리</h3>
            <p>무료 검토 <br class="p_show"/>및 <br class="p_show"/>최저비용 전자등기</p>
          </article> -->
          <!-- <article class="bx_sc_info s6">
            <h2>법정의무교육</h2>
            <h3>온라인 교육 <br/> 전액 무료!</h3>
            <p>5인 이상 기업이라면 필수<br/><span>미이수 시<br/> 과태료 부과되는<br/> 5대 법정 의무교육을<br/> 무료로!</span></p>
          </article> -->
        </div>
      </div>
    </section>
    <section class="area_media">
      <div class="inner">
        <youtube :video-id="videoId" class="i_media" ref="youtube" :player-vars="playerVars" />
      </div>
    </section>
    <swiper class="sw_usp" ref="swiper" :options="swiperUspOption" @slideChange="fnSlideUspChange">
      <swiper-slide class="sw1">
        <section class="area2">
          <div class="inner colb">
            <div class="col animate__animated" :class="aniInfo.area2 == 'true'?'animate__fadeInLeft' : 'animate__fadeOutLeft'">
              <div class="a_wrap">
                <article class="unit">
                  <h2>다된다 AI 세금환급 솔루션으로 <br/><b>3분 만에 환급금액 확인</b></h2>
                </article>
                <article class="unit">
                  <h2>최근 5년 간 낸 세금을 <br/><b>연도별, 금액별 한눈에 조회 </b></h2>
                </article>
                <article class="unit">
                  <h2>사업자번호 & 쉬운 인증으로 <br/><b>더욱 간편한 무료 진단 서비스 </b></h2>
                </article>
              </div>
            </div>
            <div class="col_img i1 animate__animated" :class="aniInfo.area2 == 'true'?'animate__fadeInRight':'animate__fadeOutRight'">
              <div class="bx_ani" :class="aniInfo.area2_1 == 'true'?'ac':'ac_no'">
                <img class="img" :src="$store.state.ui.url+'/img/main/img7_3.jpg'" alt="환급받으실 수 있는 금액은 8,325,795원 입니다">
              </div>
            </div>
          </div>
        </section>
        <aside class="area_bnr">
          <div class="inner">
            <p>다된다 <b>AI 세금환급 솔루션</b>으로 간편하고 정확하게 무료진단!</p>
          </div>
        </aside>
      </swiper-slide>
      <swiper-slide class="sw2">
        <section class="area2">
          <div class="inner colb">
            <div class="col animate__animated" :class="aniInfo.area2 == 'true'?'animate__fadeInLeft' : 'animate__fadeOutLeft'">
              <div class="a_wrap">
                <article class="unit">
                  <h2>4대보험료 절감 프로그램으로 <br/><b>1분 만에 내 사업장 보험료 검토</b></h2>
                </article>
                <article class="unit">
                  <h2>사업자번호만 입력하면 <br/><b>대상 근로자 및 절감가능 금액 확인 </b></h2>
                </article>
                <article class="unit">
                  <h2>PC와 모바일로 간편하게 인증하고  <br/><b>진단부터 환급까지 한번에 </b></h2>
                </article>
              </div>
            </div>
            <div class="col_img i2 animate__animated" :class="aniInfo.area2 == 'true'?'animate__fadeInRight':'animate__fadeOutRight'">
              <div class="bx_ani" :class="aniInfo.area2_1 == 'true'?'ac':'ac_no'">
                <img class="img" :src="$store.state.ui.url+'/img/main/img7_4.jpg'" alt="연간 TOTAL 18,299,074 원">
              </div>
            </div>
          </div>
        </section>
        <aside class="area_bnr">
          <div class="inner">
            <p><b>4대보험료 절감</b>으로 경비절감과 실수령액 증가까지!</p>
          </div>
        </aside>
      </swiper-slide>
    </swiper>
    <section class="area3">
      <div class="inner">
        <h2><b>자주묻는질문</b></h2>
        <article class="unit">
          <h3 class="blind">리스트</h3>
          <h3 class="faq_tit">세금환급 경정청구</h3>
          <ul class="list">
            <li :class="{'on': faq.toggle1==true}">
              <div class="top" @click="fnToggle(1)">
                <div class="left">
                  <h3>기존 세무사가 있는데, 상관없나요?</h3>
                </div>
                <div class="right">
                  <span class="btn_more">더보기</span>
                </div>
              </div>
              <div class="cont">
                세금환급은 전문세무사가 따로 있습니다. <br/>
                기존 세무대리인은 변경되지 않습니다.
              </div>
            </li>
            <li :class="{'on': faq.toggle2==true}">
              <div class="top" @click="fnToggle(2)">
                <div class="left">
                  <h3>세무조사 위험은 없나요?</h3>
                </div>
                <div class="right">
                  <button class="btn_more">더보기</button>
                </div>
              </div>
              <div class="cont">
                세금환급(경정청구) 신청은 누락된 공제 감면 사항에 대한 청구이므로, 세무조사 산정 사유에 해당하지 않습니다. <br/>
                세무조사 대상자는 국세기본법 제81조6에 규정된 사항에만 선정됩니다.
              </div>
            </li>
            <li :class="{'on': faq.toggle3==true}">
              <div class="top" @click="fnToggle(3)">
                <div class="left">
                  <h3>세금 환급까지 소요시간은 얼마나 되나요?</h3>
                </div>
                <div class="right">
                  <button class="btn_more">더보기</button>
                </div>
              </div>
              <div class="cont">
                다된다를 통해 3분안에 예상환급액 확인이 가능하며, <br/>
                이후 국세청 승인 및 환급액 지급까지 2개월 정도 소요됩니다.
              </div>
            </li>
          </ul>
        </article>
        <article class="unit">
          <h3 class="blind">리스트</h3>
          <h3 class="faq_tit">4대보험 절감</h3>
          <ul class="list">
            <li :class="{'on': faq.toggle4==true}">
              <div class="top" @click="fnToggle(4)">
                <div class="left">
                  <h3>5인 미만 영세사업자도 가능한가요?</h3>
                </div>
                <div class="right">
                  <span class="btn_more">더보기</span>
                </div>
              </div>
              <div class="cont">
                4대보험이 가입되어있는 근로자가 1인이라도 있다면 모두 가능합니다.
              </div>
            </li>
            <li :class="{'on': faq.toggle5==true}">
              <div class="top" @click="fnToggle(5)">
                <div class="left">
                  <h3>고용노동부 조사 위험은 없나요?</h3>
                </div>
                <div class="right">
                  <button class="btn_more">더보기</button>
                </div>
              </div>
              <div class="cont">
                소득세법에 의거하여 회사 및 근로자의 근로형태에 맞게 적용되므로 고용노동부 조사 산정 사유에 해당하지 않습니다.
              </div>
            </li>
            <li :class="{'on': faq.toggle6==true}">
              <div class="top" @click="fnToggle(6)">
                <div class="left">
                  <h3>4대보험 절감 적용 시 노무정비는 무료인가요?</h3>
                </div>
                <div class="right">
                  <button class="btn_more">더보기</button>
                </div>
              </div>
              <div class="cont">
                4대보험 절감을 위해선 기초 노무제도정비는 필수이므로 별도 비용이 발생되지 않습니다.
              </div>
            </li>
            <li :class="{'on': faq.toggle7==true}">
              <div class="top" @click="fnToggle(7)">
                <div class="left">
                  <h3>노무정비는 어떤건가요?</h3>
                </div>
                <div class="right">
                  <button class="btn_more">더보기</button>
                </div>
              </div>
              <div class="cont">
                통상시급, 연차관리, 근로계약서 등 회사가 갖고 있는 노무리스크를 현 노동법 기준에 맞춰 매년 관리합니다.
              </div>
            </li>
          </ul>
        </article>
      </div>
    </section>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
// import Vue from 'vue';
// import VueYoutube from 'vue-youtube'
import * as partnerInfo from "../constants/partnerInfo";
import BizSearch from "../components/page/BizSearch";
import {unicornranch} from "../constants/partnerInfo";

// Vue.use(VueYoutube)

export default {
  name: "index",
  layout:"main",
  components: {
    Swiper,
    SwiperSlide,
    BizSearch,
  },
	data() {
    return {
      swiperUspOption: {
        slidesPerView: 1,
        observer: true,
        observeParents: true,
        // loop: true,
        touchRatio: 0,
        autoplay: {
          delay: 4500,
          disableOnInteraction: false
        },
        effect: "fade",
      },
      view: "",
      aniInfo:{
        visual: {
          h1:"false",
          h2:"false",
          p:"false",
        },
        area2:"false",
      },
      faq: {
        toggle1 : false,
        toggle2 : false,
        toggle3 : false,
        toggle4 : false,
        toggle5 : false,
        toggle6 : false,
        toggle7 : false,
      },
      swiperOption: {
        slidesPerView: 1,
        observer: true,
        observeParents: true,
        loop: true,
        touchRatio: 0,
        autoplay: {
          delay: 7500,
          disableOnInteraction: false
        },
        effect: "fade",
        speed: 300,
        navigation: false,
        pagination: {
          el: ".sw_pagination",
          clickable: true,
        },
      },
      videoId: "nrHcICxSaSo",
      playerVars: {mute:1},
    }
	},

  beforeCreate() {
    if(process.client){
      if('test'!=this.$route.query.mode){
        // PC 사용자는 리다이렉션 시킨다.
        if(this.$isPcAndMobileWebAndHomePath(this.$appCheck())){
          const partner = partnerInfo.unicornranch;
          if(partner && partner.homeUrl){
            this.$router.push(partner.homeUrl)
          }
        }
      }
    };
    // this.$clearPartnerInfo();
  },
  // compos
  mounted(){
    this.view = this.$store.state.ui.view;
    this.fnStartVisualAni();
    window.addEventListener("scroll", () => {
      if(document.querySelector(".app").classList.contains("main")){
        // let target = document.querySelector(".visual").clientHeight/2;
        let target = document.querySelector(".visual").clientHeight;
        if(target < window.scrollY ){
          this.fnEndVisualAni();
        }else{
          if(!document.querySelector(".visual h1.animate__animated").classList.contains("animate__fadeInDown")){
            this.fnStartVisualAni();
          }
        }
        let target2 = (document.querySelector(".area1").offsetTop );
        target2 -= this.$store.state.ui.view == "pc" ? 55 : 0;
        if(window.scrollY > target2){
          this.fnStartAni("area2");
        }else{
          this.fnEndAni("area2");
        }
        // 유튜브 재생 정리
        try {
          let target3 = (document.querySelector(".area_media").offsetTop - 100);
          if(window.scrollY > target3){
            this.fnPlayVideo();
          }else{
            this.fnStopVideo();
          }
          let target4 = (document.querySelector(".area2").offsetTop );
          if(window.scrollY > target4){
            this.fnStopVideo();
          }
        } catch (error) {
          console.log(error);
        }
      }
    });

    // 스토어 클리어
    this.$store.commit('servicesStore/CLEAR')
    this.$store.commit('userStore/CLEAR')

    // 세션스토리지에 저장된 회사정보 클리어
    sessionStorage.removeItem('compNm')
    sessionStorage.removeItem('bizNo')
    sessionStorage.removeItem('compForm')

    // 백버튼 없애기
    window.onpopstate = null;
  },
  updated(){
    this.view = this.$store.state.ui.view;
  },
  methods:{
    fnToggle(i){
      this.faq["toggle"+i] = !this.faq["toggle"+i];
    },
    fnStartVisualAni(){
			if(this.aniInfo.visual.h1 == "hide"){
        this.aniInfo.visual.h1 = "false";
        this.aniInfo.visual.h2 = "false";
        this.aniInfo.visual.p = "false";
      }
      this.aniInfo.visual.h1 = "true";
      this.aniInfo.visual.h2 = "true";
      this.aniInfo.visual.p = "true";
    },
    fnSlideChange(){
      this.fnEndVisualAni();
      setTimeout(()=>{
        this.fnStartVisualAni();
      }, 400);
    },
    fnSlideUspChange(){
      this.aniInfo.area2_1 = "false";
      // this.fnEndVisualAni();
      setTimeout(()=>{
        this.aniInfo.area2_1 = "true";
      }, 200);
    },
    fnEndVisualAni(){
			if(this.aniInfo.visual.h1 == "hide"){
        this.aniInfo.visual.h1 = "false";
        this.aniInfo.visual.h2 = "false";
        this.aniInfo.visual.p = "false";
      }
      this.aniInfo.visual.h1 = "hide";
      this.aniInfo.visual.h2 = "hide";
      this.aniInfo.visual.p = "hide";
    },
    fnStartAni(name){
			if(this.aniInfo[name] == "true") this.aniInfo[name] = "false";
      this.aniInfo[name] = "true";
    },
    fnEndAni(name){
			if(this.aniInfo[name] == "false") this.aniInfo[name] = "true";
      this.aniInfo[name] = "false";
    },
    fnPlayVideo() {
      this.$refs.youtube.player.playVideo();
    },
    fnStopVideo() {
      this.$refs.youtube.player.pauseVideo();
    },
    _confirm(msg, btns){
      let data  = {
        state:true,
        type: 'confirm',
        msg: msg,
        btns: btns
      };
      this.$store.dispatch('ui/setPopupAlertData', data);
    },
    _main(msg, btns){
      let data  = {
        state:true,
        type: 'main',
        msg: msg,
        btns: btns
      };
      this.$store.dispatch('ui/setPopupAlertData', data);
    },
    __closePopup(){
      this.$store.dispatch('ui/setPopupAlertData', {
        state:false,
        type: null,
        title: null,
        msg: null,
        btn:{
          link:'',
          name:null,
        }
      });
    }
  },
  watch:{
    bizNo(newVal, oldVal){
      if(isNaN(newVal)){
        this.bizNo = oldVal;
      } else if(newVal && newVal.length > 10){
        this.bizNo = oldVal;
      }
    }
  }
}
</script>
