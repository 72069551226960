var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"area_search"},[_c('Item',{attrs:{"data":{
            id: 'form_biz_no',
            type: 'text',
            placeholder: _vm.$store.state.ui.view === 'mobile'? '사업자번호를 입력하세요.' : '사업자번호를 입력하세요.(숫자만)',
            btn: true,
            btnNm: '조회',
            dot: true,
          },"value":_vm.bizNo},on:{"update:value":function($event){_vm.bizNo=$event},"enter":_vm.fnSearchCompany,"onClick":_vm.fnSearchCompany}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }